import { SwColumn, SwContainer, SwGrid } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { i18nKeys } from '@kaa/i18n/providers/keys';
import { PageHeader } from '../../../components';
import { PrestationsCustomerStatusForm } from './PrestationsCustomerStatusForm';

export const PrestationsCustomerStatusScreen = () => {
  const { t } = useTranslation();

  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn>
          <PageHeader
            className="vl-u-spacer"
            title={t(i18nKeys.navigation.prestationsUserStatus)}
            subTitle={t(i18nKeys.prestations.customerStatus.introduction)}
          />
        </SwColumn>

        <SwColumn>
          <PrestationsCustomerStatusForm />
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
